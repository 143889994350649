<template>
    <BT-Blade-Item
        bladeName="courier-invoice"
        :bladesData="bladesData"
        navigation="courier-invoices"
        title="Courier Invoice"
        :canSave="false"
        :canDelete="false">
        <template slot-scope="{ item, data }">
            <v-list-item v-if="item != null && item.seller != null" dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(item.sellerID )" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon color="primary" >mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
            </v-list-item>

            <BT-Field-String
                v-model="item.invoiceNumber"
                label="Invoice #"
                horizontal
                :isEditing="data.isEditing" />

            <BT-Field-Date
                v-model="item.issuedOn"
                label="Issued On"
                :isEditing="data.isEditing" />

            <BT-Field-Date
                v-model="item.dueOn"
                label="Due On"
                :isEditing="data.isEditing" />

            <BT-Field-Date
                v-if="item.isPaid"
                v-model="item.paidOn"
                label="Paid On"
                :isEditing="data.isEditing" />
            <v-list-item v-else>
                <v-list-item-content>
                    <v-list-item-subtitle>Unpaid</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-expansion-panels v-model="panelV">
                <BT-Expansion-Panel-List
                    :items="item.lineItems"
                    label="Line Items"
                    :onFilter="list => { return list.orderBy(x => x.sortNumber) }">
                    <template v-slot="{ item }">
                        <v-list-item-content>
                            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="font-weight-bold"><span class="mr-1">QTY:</span>{{ item.quantity | toDisplayNumber }}{{ item.quantity == 0 && item.onOrder != 0 ? '  (Not Delivered)' : '' }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{ item.unitPrice | toCurrency }}ea</v-list-item-action-text>
                            <span>{{ (item.quantity * item.unitPrice) | toCurrency }}</span>
                        </v-list-item-action>
                    </template>
                </BT-Expansion-Panel-List>
            </v-expansion-panels>

            <h4 class="text-right ma-3">Subtotal: {{ item.subTotal | toCurrency }}</h4>
            <h4 class="text-right mx-3 mb-1">GST: {{ item.taxTotal | toCurrency }}</h4>
            
            <v-divider />
            
            <h3 class="text-right mx-3 mt-2">Total: {{ item.amountTotal | toCurrency }}</h3>

            <h2 v-if="!item.isPaid && item.amountPaid > 0" class="text-right error--text ma-5">Amount Paid: {{ item.amountPaid | toCurrency }}</h2>
            <h2 v-if="!item.isPaid" class="text-right warning--text ma-5">                            
                Amount Due: {{ item.amountTotal - item.amountPaid | toCurrency }}
            </h2>

            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
    name: 'Courier-Invoice',
    components: {        
        //BTTableBasic: () => import('~components/BT-Table-Basic.vue'),
        //BTStripeButton: () => import('~components/BT-Stripe-Button.vue')   
    },
    data: function() {
        return {
            msg: null,
            panelV: 0
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        payWithStripe(invoiceID) {
            this.$BlitzIt.api.post(
                'courier-invoice',
                null,
                null,
                '/post/pay?id=' + invoiceID)
                .then(res => {
                    console.log(res.data.data);
                    //loadStripe('pk_test_51IazF7LkrzPoErE5QXmD2lj9YPiL62gKXR0Nng4AFPfWJyVgqbS8rtKxqK3jdFUXpUUVg5LcCQjbDXd5znpsH2gf00E18k5J8k', { stripeAccount: res.data.data["AccountID"] })
                    loadStripe(res.data.data.stripeKey) //, { stripeAccount: res.data.data["AccountID"] })
                    .then(stripeRes => {
                        console.log(stripeRes);
                        stripeRes.redirectToCheckout({
                            sessionId: res.data.data.sessionID
                        })                        
                    })
                    .catch(err => {
                        console.log(err);
                    })                                                            
                })
        },
    }
}
</script>